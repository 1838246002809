import * as React from "react";
import type { HeadFC } from "gatsby";
import { useEffect } from "react";
import Header_Layout from "../../components/layouts/Header_Layout";

const ConditionsPage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <Header_Layout>
      <div
        name="termly-embed"
        data-id="97314e86-1e1f-482f-9937-2c32706371dc"
        data-type="iframe"
      ></div>
    </Header_Layout>
  );
};

export default ConditionsPage;

export const Head: HeadFC = () => <title>Privacy Notice</title>;
